import React, { Component } from "react";
import { UncontrolledCollapse } from "reactstrap";
import "./accordion.scss";

class Accordion extends Component {
  render() {
    const acc_cards = [];
    const accordion_data = this.props.accordion_data;

    accordion_data.forEach((ele, index) => {
      acc_cards.push(
        <div className="card">
          <div className="card-header">
            <h2 className="mb-0">
              <button className="btn btn-block text-left" type="button" id={"acc_" + index}>
                {ele.acc_title}
              </button>
            </h2>
          </div>

          <UncontrolledCollapse toggler={"#acc_" + index}>
            <div className="card-body">
              <p>{ele.acc_content}</p>
              {ele.additional_info !== null && ele.additional_info !== "" && ele.additional_info !== undefined && ele.additional_info.length !== 0 ? (
                <ul>
                  {ele.additional_info.map((currItem) => {
                    return <li>{currItem}</li>;
                  })}
                </ul>
              ) : null}
            </div>
          </UncontrolledCollapse>
        </div>
      );
    });

    return <div className="accordion">{acc_cards}</div>;
  }
}

export default Accordion;
