import React, { Component } from "react";
import Accordion from "../../elements/accordion/accordion";
import { cleaning_acc_data, pest_control_acc_data } from "./faq_data";

import underlineImg from "../../assets/img/underline.svg";
import "./faq.scss";

class Faq extends Component {
  render() {
    return (
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="with-underline">FAQs</h2>
              <img src={underlineImg} alt="underline" aria-hidden="true" className="underline" />

              <p className="h5">Cleaning</p>
              <Accordion accordion_data={cleaning_acc_data}></Accordion>

              <br />

              <p className="h5">Pest Control</p>
              <Accordion accordion_data={pest_control_acc_data}></Accordion>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Faq;
