import home_sanitization from "../../assets/img/services/home-sanitization.jpg";
import home_deep_cleaning from "../../assets/img/services/home-deep-cleaning.jpg";
import kitchen_cleaning from "../../assets/img/services/kitchen-cleaning.jpg";
import bathroom_cleaning from "../../assets/img/services/bathroom-cleaning.jpg";
import sofa_cleaning from "../../assets/img/services/safa-cleaning.jpg";
import carpet_cleaning from "../../assets/img/services/carpet-cleaning.jpg";
import floor_scrubing from "../../assets/img/services/floor-scrubbing.jpg";
import water_tank_cleaning from "../../assets/img/services/water-tank-cleaning.jpg";
import villa_cleaning from "../../assets/img/services/villa-cleaning.jpg";
import basic_cleaning from "../../assets/img/services/House-cleaning.png";

import office_sanitization from "../../assets/img/services/office-sanitization.jpg";
import office_deep_cleaning from "../../assets/img/services/office-deep-cleaning.jpg";

import gen_pest_control from "../../assets/img/services/pest-control-service.jpg";
import cockroach_control from "../../assets/img/services/cockroach-control.jpg";
import bedbug_control from "../../assets/img/services/bed-bug-control.jpg";
import rodent_control from "../../assets/img/services/rodent-control.jpg";

import varanda_cleaning from "../../assets/img/services/varanda-cleaning.jpg";
import parking_cleaning from "../../assets/img/services/parking-area-cleaning.jpg";
import rooftop_cleaning from "../../assets/img/services/rooftop-cleaning.jpg";
import afterparty_cleaning from "../../assets/img/services/afterparty-cleaning.jpg";

const home_cleaning_service_list = [
  {
    id: "hc_sl_1",
    image: home_sanitization,
    title: "Home Sanitation",
    approx_time: "Approximate time - 30 – 90 mins (Depending on the size of the house)",
    special_note: "Virus Fumigation Services",
    desc: "",
    additional_info: [],
    inc_excl: ["Keep food items in the refrigerator or sealed containers.", "If a home has an AC, it’s recommended they remain off prior to the treatment.", "People sensitive to pesticides must keep away during the treatment.", "DO NOT clean the treated surface before it is dried."],
    procedure: ["Aerial disinfestation will be carried out with a solution.", "The targeted area will be exposed to the disinfectant for an hour.", "Crew – 1 to 2 person’s"],
    notes: "",
  },
  {
    id: "hc_sl_2",
    image: home_deep_cleaning,
    title: "Home Deep Cleaning",
    approx_time: "Approximate time - 1 Hour/ Bathroom",
    special_note: "",
    desc: "",
    additional_info: [],
    inc_excl: ["Equipments carried - Mops, buckets, cleaning agents, dusters & garbage bags.", "Deep cleaning of Bathroom Floor and wall tiles.", "Descaling the water taps, bathtub/shower cubicle & cleaning WC seat.", "Cleaning of all bathroom fittings, exhaust fans and geyser.", "Cleaning of mirrors, windows and doors.", "Eco - Friendly Chemical used for cleaning.", "Hard water stains can be removed upto 70 -80%."],
    procedure: "",
    notes: "",
  },
  {
    id: "hc_sl_3",
    image: kitchen_cleaning,
    title: "Kitchen Cleaning",
    approx_time: "",
    special_note: "",
    desc: "",
    additional_info: [
      {
        title: "Chimney Cleaning",
        values: ["Modern times has brought us modern appliances. These modern appliances include chimneys in kitchens that have made the life of a cook much easier. But these sophisticated modern appliances need sophisticated cleaning. Chimney cleaning requires technical knowledge and special tools, if you want your chimney clean and working efficiently, you need to get it cleaned at regular intervals. We at Trusttidy offer you top of the line chimney cleaning services with loads of other services, all at a one stop destination in a bid to make your life easier."],
      },
    ],
    inc_excl: ["Stain Removal, sanitize and disinfect the house with Eco - Friendly chemicals.", "Equipment carried - Mops, buckets, cleaning agents, dusters & garbage bags.", "Crew Size – 1 to 2 person’s (Depending on the size of Kitchen)", "Cleaning of Kitchen walls included."],
    procedure: ["Scrubbing of the floors, vacuuming, scrubbing of tiled walls, descaling of taps, dusting & wiping of counter & cupboards and cleaning of Chimneys, exhaust fans will be degreased externally."],
    notes: "",
  },
  {
    id: "hc_sl_4",
    image: bathroom_cleaning,
    title: "Bathroom Cleaning",
    approx_time: "",
    special_note: "",
    desc: "",
    additional_info: [],
    inc_excl: ["Stain Removal, sanitize and disinfect the house with Eco - Friendly chemicals.", "Equipment carried - Mops, buckets, cleaning agents, dusters & garbage bags.", "Crew Size – 1 to 2 person’s (Depending on the size of Bathrooms)", "Cleaning of Bathroom walls included."],
    procedure: ["Scrubbng the floor & wall tiles, descaling the water taps & cleaning WC seat,bathtub/shower cubicle."],
    notes: "",
  },
  {
    id: "hc_sl_5",
    image: sofa_cleaning,
    title: "Sofa Cleaning",
    approx_time: "Approximate time - 1Hr (Depending on the size & condition of the sofa)",
    special_note: "",
    desc: "",
    additional_info: [],
    inc_excl: ["Note - Sofa will be moist for 5 to 6 hours depending on the Climate condition.", "Sofa shampooing only for seat.", "Rexine & Cotton sofa's cannot be shampooed.", "Cushions & Puffy are not included.", "Oil & Ink Stains can be removed upto 40%."],
    procedure: ["Dry Vacuuming the sofa.", "Manual Scrubbing/ Brushing sofa with a chemical solution.", "Wet Vacuuming sofa to remove dirty water.", "Equipment Carried - Hand scrubbing brush/ machine, dry & wet vacuum cleaner, mops, buckets, Eco-friendly Chemicals, dusters & garbage bags.", "Size of Crew – 1 to 2 person’s"],
    notes: ["Walls having distemper paints is not recommended to be cleaned with water or any chemicals and would be dry dusted. Any textured, patterned and wall coverings will be only dry dusted without any chemical usage to avoid any unwanted damage.", "Heavy fixtures and furniture would not be moved by our team.", "Old and stubborn stains will be attended to the best of our ability. However we can’t guarantee 100% stain removal."],
  },
  {
    id: "hc_sl_6",
    image: carpet_cleaning,
    title: "Carpet Cleaning",
    approx_time: "Approximate time - 1 to 2 Hrs (Depending on the size and condition of the carpet)",
    special_note: "",
    desc: "",
    additional_info: [],
    inc_excl: ["Carpet will be moist under normal weather.", "Mold Remediation.", "Water Damage / Food-Oil- beverages stain.", "Upholstery Cleaning.", "Carpets made of Woollen, Feather & Rug will not be cleaned.", "Damaged parts cannot be covered."],
    procedure: ["Dry vacuuming on carpet, Spot cleaning for stain removal.", "Scrubbing/ Brushing Carpet with a chemical solution.", "Wet Vacuuming the carpet to remove dirty water.", "Equipment Carried - Scrubbing machine, dry & wet vacuum cleaner, buckets, cleaning agents, dusters & garbage bags.", "Crew - 1 to 2 persons"],
    notes: ["Any hard/major stains may not immediately be removed.", "A periodic cleaning will be required for the same.", "Will take upto 6 hours for completely drying and should not be used till completely dry."],
  },
  {
    id: "hc_sl_7",
    image: floor_scrubing,
    title: "Floor Scrubbing & Polishing",
    approx_time: "Approximate time - 2 - 6 Hrs (Depending on the size and type of the floor)",
    special_note: "(Both for Residential and Commercial purposes)",
    desc: "",
    additional_info: [],
    inc_excl: ["Equipments Carried - Hand scrubbing brush or machine, dry & wet vacuum cleaner, mops, buckets, cleaning agents, dusters & garbage bags.", "Crew - 2 person’s or more (Depending on the size)"],
    procedure: ["Cleaning and scrubbing the floor focusing on dirt removal from pores, stain removal and restoring shine."],
    notes: [],
  },
  {
    id: "hc_sl_8",
    image: water_tank_cleaning,
    title: "Tank Cleaning",
    approx_time: "",
    special_note: "",
    desc: "Proper maintenance of the overhead tank and the underground sump is a vital task since the water stored there is used in all our daily chores and might also be the source of water consumption. The first step towards having access to clean water is keeping the overhead tank clean. So its extremely essential to clean the tank and sump in regular intervals to ensure that the water does not get contaminated. We have a dedicated team of professionals who take care of overhead tank and sump cleaning.",
    additional_info: [],
    inc_excl: "",
    procedure: "",
    notes: "",
  },
  {
    id: "hc_sl_9",
    image: villa_cleaning,
    title: "Villa Deep Cleaning",
    approx_time: "",
    special_note: "",
    desc: "Looking for Deep Cleaning Service of your Villa? Trusttidy provides you the best Professional Villa Cleaning Service. We are pretty sure that you will surely get satisfied by the service. Our professionals are fully verified by us. So that, it never need to worry about anything. Booking villa deep cleaning service from Trusttidy, you will always get the best rates. Our professional villa cleaning service includes, kitchen cleaning, bathroom cleaning, bedroom cleaning, floor cleaning, window cleaning, furniture cleaning, every corner cleaning, appliances cleaning, balcony cleaning, sweeping etc. and make your Villa shine like new.",
    additional_info: [],
    inc_excl: "",
    procedure: "",
    notes: "",
  },
  {
    id: "hc_sl_10",
    image: basic_cleaning,
    title: "Basic Cleaning",
    approx_time: "",
    special_note: "",
    desc: "For more inofrmation or booking please contact 9538 97 98 99.",
    additional_info: [],
    inc_excl: "",
    procedure: "",
    notes: "",
  },
];

const corporate_cleaning_service_list = [
  {
    id: "cl_sl_1",
    image: office_sanitization,
    title: "Office Sanitation",
    approx_time: "Approximate time - 60 - 180 mins (Depending on the size of the office). Rs 0.5/sqft Onwards + On Inspection",
    special_note: "Virus Fumigation Services",
    desc: "First impression always counts, having an immaculate clean environment will most definitely attract much more clients to the business. A clean workplace is also known to increase staff productivity and decrease absence caused by illness. We use the most up-to-date ergonomically designed, environmentally friendly cleaning equipment and materials. Our cleaning Process enable us to deliver quality cleaning services while helping you to meet your environmental and corporate responsibilities. For more information or booking please contact on 9538 97 98 99.",
    additional_info: [],
    inc_excl: ["People sensitive to pesticides must keep away during the treatment.", "DO NOT clean the treated surface before it is dried."],
    procedure: ["Aerial disinfestation will be carried out with a solution.", "The targeted area will be exposed to the disinfectant for an hour.", "Crew – 1 to 2 person’s"],
    notes: "",
  },
  {
    id: "cl_sl_2",
    image: office_deep_cleaning,
    title: "Office Deep Cleaning",
    approx_time: "Approximate time - 2 Hour/ Bathroom",
    special_note: "",
    desc: "We provides highly reliable corporate cleaning services to ensure safe, clean and healthy working space. We have the most competent team to handle the essential requirement for your office cleaning. We are having right cleaning team and skilled staff, we always try to deliver the highest quality in office cleaning services to fulfil client’s requirement.",
    additional_info: [
      {
        title: "Reception Area Deep Cleaning",
        values: ["Dusting of furniture", "Dusting and cleaning of partition glasses and light fixtures", "Removal of dirt and dust", "Polishing of hardwood surfaces"],
      },
      {
        title: "Overall Office Deep Cleaning",
        values: ["Dusting of cushions, ceilings, telephones, desks, chairs and tables", "General Dusting of computers, keyboards, mouse, etc.", "Carpet vacuuming", "Cleaning individual cubicles/workstations"],
      },
      {
        title: "Office Washroom Deep Cleaning",
        values: ["Restroom Cleaning and Sanitizing", "Vent and blind Dusting", "Wash Closet and Wash Basin Cleaning", "Stainless and spotless Mirrors"],
      },
    ],
    inc_excl: ["The crew is not to be asked to displace furniture, fixtures or any kind of appliances", "Wall cleaning and scrubbing is not included in any other rooms except for washrooms"],
    procedure: "",
    notes: "",
  },
  {
    id: "cl_sl_3",
    image: floor_scrubing,
    title: "Floor Scrubbing & Polishing",
    approx_time: "Approximate time - 2 - 6 Hrs (Depending on the size and type of the floor)",
    special_note: "",
    desc: "",
    additional_info: [],
    inc_excl: ["Equipments Carried - Hand scrubbing brush or machine, dry & wet vacuum cleaner, mops, buckets, cleaning agents, dusters & garbage bags.", "Crew - 2 person’s or more (Depending on the size)"],
    procedure: ["Cleaning and scrubbing the floor focusing on dirt removal from pores, stain removal and restoring shine."],
    notes: [],
  },
  {
    id: "cl_sl_4",
    image: carpet_cleaning,
    title: "Carpet Cleaning",
    approx_time: "Approximate time - 1 to 2 Hrs (Depending on the size and condition of the carpet)",
    special_note: "",
    desc: "",
    additional_info: [],
    inc_excl: ["Carpet will be moist under normal weather.", "Mold Remediation.", "Water Damage / Food-Oil- beverages stain.", "Upholstery Cleaning.", "Carpets made of Woollen, Feather & Rug will not be cleaned.", "Damaged parts cannot be covered."],
    procedure: ["Dry vacuuming on carpet, Spot cleaning for stain removal.", "Scrubbing/ Brushing Carpet with a chemical solution.", "Wet Vacuuming the carpet to remove dirty water.", "Equipment Carried - Scrubbing machine, dry & wet vacuum cleaner, buckets, cleaning agents, dusters & garbage bags.", "Crew - 1 to 2 persons"],
    notes: ["Any hard/major stains may not immediately be removed.", "A periodic cleaning will be required for the same.", "Will take upto 6 hours for completely drying and should not be used till completely dry."],
  },
];

const pest_control_service_list = [
  {
    id: "pc_sl_1",
    image: gen_pest_control,
    title: "General Pest Control",
    approx_time: "",
    special_note: "",
    desc: "For more inofrmation or booking please contact 9538 97 98 99.",
    additional_info: [],
    inc_excl: "",
    procedure: "",
    notes: "",
  },
  {
    id: "pc_sl_2",
    image: cockroach_control,
    title: "Cockroach Control",
    approx_time: "",
    special_note: "",
    desc: "For more inofrmation or booking please contact 9538 97 98 99.",
    additional_info: [],
    inc_excl: "",
    procedure: "",
    notes: "",
  },
  {
    id: "pc_sl_3",
    image: bedbug_control,
    title: "Bed Bugs Removal",
    approx_time: "",
    special_note: "",
    desc: "For more inofrmation or booking please contact 9538 97 98 99.",
    additional_info: [],
    inc_excl: "",
    procedure: "",
    notes: "",
  },
  {
    id: "pc_sl_4",
    image: rodent_control,
    title: "Rodent Control",
    approx_time: "",
    special_note: "",
    desc: "For more inofrmation or booking please contact 9538 97 98 99.",
    additional_info: [],
    inc_excl: "",
    procedure: "",
    notes: "",
  },
];

const exterior_cleaning_service_list = [
  {
    id: "ec_sl_1",
    image: varanda_cleaning,
    title: "Veranda Cleaning",
    approx_time: "",
    special_note: "",
    desc: "For more inofrmation or booking please contact 9538 97 98 99.",
    additional_info: [],
    inc_excl: "",
    procedure: "",
    notes: "",
  },
  {
    id: "ec_sl_2",
    image: parking_cleaning,
    title: "Parking Area Cleaning",
    approx_time: "",
    special_note: "",
    desc: "For more inofrmation or booking please contact 9538 97 98 99.",
    additional_info: [],
    inc_excl: "",
    procedure: "",
    notes: "",
  },
  {
    id: "ec_sl_3",
    image: rooftop_cleaning,
    title: "Roof Top Cleaning",
    approx_time: "",
    special_note: "",
    desc: "For more inofrmation or booking please contact 9538 97 98 99.",
    additional_info: [],
    inc_excl: "",
    procedure: "",
    notes: "",
  },
  {
    id: "ec_sl_4",
    image: afterparty_cleaning,
    title: "After Party Cleaning",
    approx_time: "",
    special_note: "",
    desc: "It is always a headache to clean up the mess that is left behind after an amazing party, the bigger and wilder a party the bigger the job of cleaning it. We at Trusttidy offer you some of the best professionals for after party cleaning services. It doesn’t matter if you are hungover or just do not want to clean it up all by yourself, our professionals will help you clean the place up like nothing happened there in a while. Our professional House Cleaning won’t leave a corner untouched! Our party cleaning teams come equipped to tackle the dirtiest jobs and will ensure your property is returned to pristine condition.",
    additional_info: [],
    inc_excl: "",
    procedure: "",
    notes: "",
  },
];

export { home_cleaning_service_list, corporate_cleaning_service_list, pest_control_service_list, exterior_cleaning_service_list };
