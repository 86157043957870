import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "./footer.scss";

class Footer extends Component {
  render() {
    return (
      <section id="footer">
        <div className="container">
          {/* <div className="row text-center text-xs-center text-sm-left text-md-left">
            <div className="col-xs-12 col-sm-4 col-md-4">
              <h5>Quick links</h5>
              <ul className="list-unstyled quick-links">
                <li>
                  <a href="/">
                    <i className="fa fa-angle-double-right"></i>
                    Home
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i className="fa fa-angle-double-right"></i>
                    About
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i className="fa fa-angle-double-right"></i>
                    FAQ
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i className="fa fa-angle-double-right"></i>
                    Get Started
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i className="fa fa-angle-double-right"></i>
                    Videos
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <h5>Quick links</h5>
              <ul className="list-unstyled quick-links">
                <li>
                  <a href="/">
                    <i className="fa fa-angle-double-right"></i>Home
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i className="fa fa-angle-double-right"></i>About
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i className="fa fa-angle-double-right"></i>FAQ
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i className="fa fa-angle-double-right"></i>Get Started
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i className="fa fa-angle-double-right"></i>Videos
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xs-12 col-sm-4 col-md-4">
              <h5>Quick links</h5>
              <ul className="list-unstyled quick-links">
                <li>
                  <a href="/">
                    <i className="fa fa-angle-double-right"></i>Home
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i className="fa fa-angle-double-right"></i>About
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i className="fa fa-angle-double-right"></i>FAQ
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i className="fa fa-angle-double-right"></i>Get Started
                  </a>
                </li>
                <li>
                  <a href="https://wwwe.sunlimetech.com" title="Design and developed by">
                    <i className="fa fa-angle-double-right"></i>Imprint
                  </a>
                </li>
              </ul>
            </div>
          </div> */}

          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2">
              <ul className="footer-nav-links">
                <li>
                  <NavLink exact to="/" className="nav-link" activeClassName="active">
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/about-us" className="nav-link" activeClassName="active">
                    About us
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/our-services" className="nav-link" activeClassName="active">
                    Our Services
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/faq" className="nav-link" activeClassName="active">
                    FAQs
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/privacy-policy" className="nav-link" activeClassName="active">
                    Our Privacy & Policy
                  </NavLink>
                </li>
              </ul>
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2">
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2">
                  <ul className="list-unstyled list-inline social text-center">
                    <li className="list-inline-item">
                      <a href="/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>

                    <li className="list-inline-item">
                      <a href="https://twitter.com/TidyTrust" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>

                    <li className="list-inline-item">
                      <a href="https://www.linkedin.com/in/trust-tidy-6178a31b2/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>

                    <li className="list-inline-item">
                      <a href="https://www.youtube.com/channel/UCOgC69k8jrf6SO7_xImD_bQ?view_as=subscriber" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-youtube"></i>
                      </a>
                    </li>

                    <li className="list-inline-item">
                      <a href="https://www.instagram.com/trusttidy1/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>

                    <li className="list-inline-item">
                      <a href="https://www.pinterest.com/TrustTidy1/" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-pinterest-p"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <hr />
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
                  <p className="h6">© 2020 Trusttidy Online Service. All Rights Reserved.</p>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
              <p>
                <u>
                  <a href="https://www.nationaltransaction.com/">National Transaction Corporation</a>
                </u>
                is a Registered MSP/ISO of Elavon, Inc. Georgia [a wholly owned subsidiary of U.S. Bancorp, Minneapolis, MN]
              </p>
              <p className="h6">© 2020 Trusttidy Online Service. All Rights Reserved.</p>
            </div>
            <hr />
          </div> */}
        </div>
      </section>
    );
  }
}

export default Footer;
