import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./404.scss";
import notfoundThumb from "../../assets/img/undraw_warning_cyit.svg";

class Notfound extends Component {
  render() {
    return (
      <section className="not-found-section">
        <div className="container">
          <div>
            <h1>404: Page not found!</h1>
            <p>
              You seem to have clicked on a broken link or entered a URL that doesn't exist on this site. Visit <Link to="/">Homepage</Link>.
            </p>

            <br />

            <img src={notfoundThumb} alt="Access Denied Thumbnail" className="notfound-thumb" />
          </div>
        </div>
      </section>
    );
  }
}

export default Notfound;
