import React, { Component } from "react";
import underlineImg from "../../assets/img/underline.svg";
import "./terms.scss";

class Terms extends Component {
  render() {
    return (
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2 className="with-underline">Our Privacy & Policy</h2>
              <img src={underlineImg} alt="underline" aria-hidden="true" className="underline" />

              <p>This Privacy & policy was last updated: July 17th, 2020 This Privacy & Policy of Use agreement is effective as of: July 3rd, 2020.</p>

              <p>
                We value the trust you place in us. That's why we insist upon the highest standards for secure transactions and customer information privacy.
                <br />
                Please read the following statement to learn about our information gathering and dissemination practices.
              </p>

              <p>
                <strong>Note:</strong>
                <br />
                Our privacy policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this policy periodically.
              </p>

              <p>By visiting this Website you agree to be bound by the terms and conditions of this Privacy Policy. If you do not agree please do not use or access our Website.</p>

              <p>By mere use of the Website, you expressly consent to our use and disclosure of your personal information in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms of Use.</p>

              <br />

              <h3>Privacy Policy</h3>
              <p>Trusttidy Online Services ("Trusttidy") values your privacy. In this Privacy Policy ("Policy"), we describe the information that we collect about you when you visit our website, www.trusttidy.com (the "Website") and use the services available on the Website ("Services"), and how we use and disclose that information.</p>

              <p>If you have any questions or comments about the Privacy Policy, please contact us at support@trusttidy.com. This Policy is incorporated into and is subject to the Trusttidy Terms of Use, which can be accessed at www.trusttidy.com/#/privacy-policy. Your use of the Website and/or Services and any personal information you provide on the Website remains subject to the terms of the Policy and Trusttidy's Terms of Use.</p>

              <p>In terms of the Information Technology (Intermediaries Guidelines) Rules, 2011 and the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011, any grievance or complaint may be intimated to us. The complaint shall be redressed within a period of 1 (one) month.</p>

              <ol className="list-upper-alpha">
                <li className="h5">
                  Collection Of Your Personal Information
                  <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                    <ol>
                      <li style={{ fontWeight: "500" }}>
                        PERSONAL INFORMATION
                        <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                          <p>“Personal information” is defined to include information that whether on its own or in combination with other information may be used to readily identify or contact you such as: name, address, email address, phone number etc.</p>

                          <p>We collect personal information from Service Professionals offering their products and services. This information is partially or completely accessible to all visitors using Trusttidy’s website or mobile application, either directly or by submitting a request for a service. Service Professionals and customers are required to create an account to be able to access certain portions of our Website, such as to submit questions, participate in polls or surveys, to request a quote, to submit a bid in response to a quote, and request information. - Service Professionals, if and when they create an account with Trusttidy, will be required to disclose information including personal contact details, agree to participation in polls or surveys or feedbacks or any other information that can help customer satiate its needs. The type of personal information that we collect from you varies based on your particular interaction with our Website or mobile application.</p>

                          <p>Consumers: During the Account registration process, we will collect information such as your name, phone number, email id, postal address, and other personal information. You also may provide us with your, demographic information (e.g., gender, age, education, race or ethnic origin, and other information relevant to user surveys and/or offers). We may also collect personal information that you post in your Offer, Profile, Wants, or Feedback, and any comments or discussions you post in any blog, chat room, or other correspondence site on the Website or mobile application, or any comments you provide during dispute resolution with other users of the Website or mobile application.</p>

                          <p>Trusttidy reserves the right to record the conversations between consumers facilitated by Trusttidy through the messaging/chat mechanism on the platform or the calls made through the virtual numbers provided to safeguard the privacy of consumers. All the chat/messaging logs or call recordings can be used to but not limited to monitor and prohibit abuse, safeguard the rights of consumers, resolve disputes.</p>

                          <p>If we deem it necessary, in our sole and absolute discretion, we may also ask for and collect supplemental information from third parties, such as information about your credit from a credit bureau (to the extent permitted by law), or information to verify any identification details you provide during registration.</p>
                        </div>
                      </li>

                      <li style={{ fontWeight: "500" }}>
                        COLLECTION OF PERSONAL INFORMATION FROM SOCIAL NETWORKING SITES
                        <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                          <p>You may log into our Website through your Facebook account. If you do so, you must enter the email address and password that you use for your Facebook account. We will ask that you grant us permission to access and collect your Facebook basic information (this includes your name, profile picture, gender, networks, user IDs, list of friends, date of birth, email address, and any other information you have set to public on your Facebook account). If you allow us to have access to this information, then we will have access to this information even if you have chosen not to make that information public.</p>

                          <p>We store the information that we receive from Facebook with other information that we collect from you or receive about you.</p>

                          <p>Facebook controls the information it collects from you. For information about how Facebook may use and disclose your information, including any information you make public, please consult Facebook's privacy policy. We have no control over how any third party site uses or discloses the personal information it collects about you.</p>
                        </div>
                      </li>

                      <li style={{ fontWeight: "500" }}>
                        COLLECTION OF AUTOMATIC INFORMATION, USE OF COOKIES AND OTHER TRACKING DEVICES
                        <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                          <p>We and our third party service providers, which include ad networks, use cookies, web beacons, and other tracking technologies to collect information about your use of our Website and Services, such as your browser type, your ISP or operating system, your domain name, your access time, the URL of the previous website you visited, your page views, your IP address, and the type of device that you use. We also track how frequently you visit our Website and use our Services. We use this information (including the information collected by our third party service providers) for Website analytics (including to determine which portions of our Website are used most frequently, what our users like/do not like), to assist us in determining relevant advertising (both on and off our Website), to evaluate the success of our advertising campaigns, and as otherwise described in this policy. Currently, we do not honour browser requests not to be tracked.</p>

                          <p>We may obtain your device ID, which is sent to Trusttidy's servers and used in fraud prevention efforts.</p>

                          <p>Cookies. We and our third party service providers collect information from you by using cookies. A cookie is a small file stored on user's computer hard drive containing information about the user. The cookie helps us analyze web traffic or informs you about your use of a particular website. Cookies allow web applications to respond to you as an individual, tailoring its operations to your needs, likes and dislikes by gathering and remembering information about your preferences. When you visit the Website, we may send one or more cookies (i.e., a small text file containing a string of alphanumeric characters) to your computer that identifies your browser.</p>

                          <p>Some of these cookies may be connected to third-party companies or websites. The terms of use of such cookies are governed by this Policy and the privacy policy of the relevant third party company or website. For example, Google measures the performance of advertisements by placing cookies on your computer when you click on ads. If you visit the Website when you have such cookies on your computer, we and Google will be able to tell that you saw the ad delivered by Google. The terms of use of these cookies are governed by this Policy and Google's Privacy Policy.</p>

                          <p>Disabling Cookies. You can choose to accept or decline cookies. Most web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you disable cookies you may be prevented from taking full advantage of the Website because it may not function properly if the ability to accept cookies is disabled.</p>

                          <p>Clear GIFs, pixel tags and other technologies. Clear GIFs are tiny graphics with a unique identifier, similar in function to cookies. In contrast to cookies, which are stored on your computer's hard drive, clear GIFs are embedded invisibly on web pages. We may use clear GIFs (a.k.a. web beacons, web bugs or pixel tags), in connection with our Website to, among other things, track the activities of Website visitors, help us manage content, and compile statistics about Website usage. We and our third party service providers also use clear GIFs in HTML emails to our customers, to help us track email response rates, identify when our emails are viewed, and track whether our emails are forwarded.</p>
                        </div>
                      </li>
                    </ol>
                  </div>
                </li>

                <li className="h5">
                  How Trusttidy Uses the Information We Collect
                  <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                    <p>We collect your personal information and aggregate information about the use of our Website and Services to better understand your needs and to provide you with a better Website experience. Specifically, we may use your personal information for any of the following reasons:</p>

                    <ul>
                      <li>To provide our Services to you, including registering you for our Services, verifying your identity and authority to use our Services, and to otherwise enable you to use our Website and our Services;</li>
                      <li>For customer support and to respond to your inquiries;</li>
                      <li>For internal record-keeping purposes;</li>
                      <li>To process billing and payment, including sharing with third party payment gateways in connection with Website and/or Trusttidy's products and Services;</li>
                      <li>To improve and maintain our Website and our Services (for example, we track information entered through the "Search" function; this helps us determine which areas of our Website users like best and areas that we may want to enhance; we also will use for trouble-shooting purposes, where applicable);</li>
                      <li>To periodically send promotional emails to the email address you provide regarding new products from Trusttidy, special offers from Trusttidy or other information about Trusttidy that we think you may find interesting;</li>
                      <li>To contact you via email, telephone, facsimile or mail, or, where requested, by text message, to deliver certain services or information you have requested;</li>
                      <li>For Trusttidy's market research purposes, including, but not limited to, the customization of the Website according to your interests;</li>
                      <li>We may use your demographic information (i.e., age, postal code, residential and commercial addresses, and other various data) to more effectively facilitate the promotion of goods and services to appropriate target audiences and for other research and analytical purposes;</li>
                      <li>To resolve disputes, to protect ourselves and other users of our Website and Services, and to enforce our Terms of Use;</li>
                      <li>We also may compare personal information collected through the Website and Services to verify its accuracy with personal information collected from third parties; and</li>
                      <li>We may combine aggregate data with the personal information we collect about you.</li>
                    </ul>

                    <br />

                    <p>From time to time, Trusttidy may use personal information for new and unanticipated uses not previously disclosed in our Privacy Policy. If our information practices change regarding information previously collected, Trusttidy shall make reasonable efforts to provide notice and obtain consent of any such uses as may be required by law.</p>
                  </div>
                </li>

                <li className="h5">
                  Electronic Newsletters, Invitations, Polls and Surveys
                  <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                    <p>At our sole discretion, Trusttidy may offer any of the following free services on the Website, which you may select to use or receive at your option. Certain of the following services may require you to provide additional personal information as detailed below:</p>

                    <ol>
                      <li style={{ fontWeight: "500" }}>
                        ELECTRONIC NEWSLETTERS
                        <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                          We may offer a free electronic newsletter to users. We will gather the email addresses of users who sign up for Trusttidy for the newsletter mailing list. Users may remove themselves from this mailing list by opting out of receiving newsletters during the registration process, by following the link provided in each newsletter that points users to a subscription management page where the user can unsubscribe from receiving newsletters or by changing their preferences in their Profile Settings page.
                        </div>
                      </li>

                      <li style={{ fontWeight: "500" }}>
                        "SEND TO A FRIEND"
                        <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                          Our Website users can voluntarily choose to electronically forward a link, page, or document to someone else by clicking "send to a friend." To do so, the user must provide his or her email address, as well as the email address of the recipient. The user's email address is used only in the case of transmission errors and, of course, to let the recipient know who sent the email. The information is not used for any other purpose.
                        </div>
                      </li>

                      <li style={{ fontWeight: "500" }}>
                        POLLING
                        <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                          We may offer interactive polls to users so they can easily share their opinions with other users and see what our audience thinks about important issues, Services, and/or the Website. Opinions or other responses to polls are aggregated and are not identifiable to any particular user. We may use a system to "tag" users after they have voted, so they can vote only once on a particular question. This tag is not correlated with information about individual users.
                        </div>
                      </li>

                      <li style={{ fontWeight: "500" }}>
                        SURVEYS
                        <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                          We may conduct user surveys from time to time to better target our content to our Website users. We will not share individual responses from these surveys with any third party. We will share aggregate data with third party service providers, partners, and other third parties. We also will post aggregate data containing survey responses on our Website; that data may be viewed and downloaded by other users of our Website.
                        </div>
                      </li>
                    </ol>
                  </div>
                </li>

                <li className="h5">
                  Disclosure
                  <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                    <p>We may share the information that we collect about you, including your personal information, as follows:</p>

                    <ol>
                      <li style={{ fontWeight: "500" }}>
                        INFORMATION DISCLOSED TO PROTECT US AND OTHERS
                        <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                          We may disclose your information including Personal Information if: (i) Trusttidy reasonably believes that disclosure is necessary in order to comply with a legal process (such as a court order, search warrant, etc.) or other legal requirement of any governmental authority, (ii) disclosure would potentially mitigate our liability in an actual or potential lawsuit, (iii) reasonably necessary to enforce this Privacy Policy, our Terms of Use etc. (iv) disclosure is intended to help investigate or prevent unauthorized transactions or other illegal activities, or (v) necessary or appropriate to protect our rights or property, or the rights or property of any person or entity.
                        </div>
                      </li>

                      <li style={{ fontWeight: "500" }}>
                        INFORMATION DISCLOSED TO THIRD PARTY SERVICE PROVIDERS AND BUSINESS PARTNERS
                        <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                          We may contract with various third parties for the provision and maintenance of the Website, Services and our business operations, and Trusttidy may need to share your personal information and data generated by cookies and aggregate information (collectively, "information") with these vendors and service agencies. For example, we may provide your information to a credit card processing company to process your payment. The vendors and service agencies will not receive any right to use your personal information beyond what is necessary to perform its obligations to provide the Services to you. If you complete a survey, we also may share your information with the survey provider; if we offer a survey in conjunction with another entity, we also will disclose the results to that entity.
                        </div>
                      </li>
                    </ol>
                  </div>
                </li>

                <li className="h5">
                  Links to External Websites
                  <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                    <p>The Website may contain links to other websites or resources over which Trusttidy does not have any control. Such links do not constitute an endorsement by Trusttidy of those external websites. You acknowledge that Trusttidy is providing these links to you only as a convenience, and further agree that Trusttidy is not responsible for the content of such external websites. We are not responsible for the protection and privacy of any information which you provide while visiting such external websites and such sites are not governed by this Policy. Your use of any external website is subject to the terms of use and privacy policy located on the linked to external website.</p>
                  </div>
                </li>

                <li className="h5">
                  Updating, Deleting and Correcting Your Personal Information
                  <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                    <p>
                      You may choose to restrict the collection or use of your personal information in the following ways: You can review, correct and delete your personal information by logging into the Website and navigating to your preferences page in "Edit Profile." You must promptly update your personal information if it changes or is inaccurate. Typically, we will not manually alter your personal information because it is very difficult to verify your identity remotely. Nonetheless, upon your request we will close your Account and remove your personal information from view as soon as reasonably possible based on your Account activity and in accordance with applicable law. We do retain information from closed Accounts in order to comply with the law, prevent fraud, collect any fees owed, resolve disputes, troubleshoot problems, assist with any investigations of any Registered User, enforce our Terms of Use, and take any other actions otherwise permitted by law that we deem
                      necessary in our sole and absolute discretion. You should understand, however, that once you publicly post a Request, Offer, Want, Feedback, or any other information onto the Website, you may not be able to change or remove it. Once we have deleted or removed your Account, you agree that Trusttidy shall not be responsible for any personal information that was not included within your deleted and/or removed Account that remains on the Website.
                    </p>
                  </div>
                </li>

                <li className="h5">
                  What Choices Do I Have Regarding Use of My Personal Information?
                  <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                    <p>We may send periodic promotional or informational emails to you. You may opt-out of such communications by following the opt-out instructions contained in the email. Please note that it may take up to 10 business days for us to process opt-out requests. If you opt-out of receiving emails about recommendations or other information we think may interest you, we may still send you emails about your Account or any Services you have requested or received from us.</p>
                  </div>
                </li>

                <li className="h5">
                  Third-Party Ad Networks
                  <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                    <p>We participate in third party ad networks that may display advertisements on other websites based on your visits to our Site as well as other websites. This enables us and these third parties to target advertisements by displaying ads for products and services in which you might be interested. Third party ad network providers, advertisers, sponsors and/or traffic measurement services may use cookies, JavaScript, web beacons (including clear GIFs), Flash LSOs and other technologies to measure the effectiveness of their ads and to personalize advertising content to you. These third party cookies and other technologies are governed by each third party's specific privacy policy, and not by Trusttidy’s Policy. We may provide these third-party advertisers with information about your usage of our Site and our services, as well as aggregate or non-personally identifiable information about visitors to our Site and users of our service.</p>
                  </div>
                </li>

                <li className="h5">
                  Your Full Name and Use of Trusttidy Information
                  <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                    <p>We may share the information that we collect about you, including your personal information, as follows:</p>

                    <ol>
                      <li style={{ fontWeight: "500" }}>
                        YOUR FULL NAME
                        <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                          <p>As a Registered User of the Website you will select a Full Name during the registration process. All of your activities on the Website will be traceable to your Full Name. Certain other people, including other Registered Users with whom you have transacted business via the Website, can see a large part of your activity on the Website. If you book a service with a Registered User, cancel a scheduled service with a Registered User, receive an offer on your posted service from a Registered User, or have posted a service, Trusttidy may send a notice to you or the appropriate Registered User that includes your Full Name. Thus, if you associate your real name with your Full Name, the people to whom you have revealed your name may be able to personally identify your Website activities.</p>
                        </div>
                      </li>

                      <li style={{ fontWeight: "500" }}>
                        USING INFORMATION FROM Trusttidy
                        <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                          <p>The Website facilitates your sharing of personal information with others in order to negotiate, provide, and use the Services. If you agree to contract for a service with another Registered User, you may need to reveal your name, email, phone number, or personal address to that individual so that the service may be performed. Please respect the privacy of others. You agree to use the information of other users solely for the following purposes: (a) Trusttidy-transaction-related purposes; and (b) using Services offered through the Website.</p>
                        </div>
                      </li>

                      <li style={{ fontWeight: "500" }}>
                        ACCOUNT PROTECTION
                        <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                          <p>Your password is the key to your Account. When creating your password you should use unique numbers, letters, special characters, and combinations thereof. In addition, DO NOT disclose your password to anyone. If you do share your password or your personal information with others, you are solely responsible for all actions taken via your Account. If you lose control of your password, you may lose substantial control over your personal information and be subject to legally binding actions taken on your behalf. Thus, IF YOUR PASSWORD HAS BEEN COMPROMISED FOR ANY REASON, YOU MUST IMMEDIATELY NOTIFY Trusttidy TO CHANGE YOUR PASSWORD.</p>
                        </div>
                      </li>
                    </ol>
                  </div>
                </li>

                <li className="h5">
                  User Generated Content
                  <div className="normal-font" style={{ marginTop: "0.5rem" }}>
                    <p>We invite you to post content on our Website, including your comments, feedback, pictures, and any other information that you would like to be available on our Website. If you post content to our Website, all of the information that you post will be available to all visitors to our Website. If you post your own content on our Website or Services, your posting may become public and Trusttidy cannot prevent such information from being used in a manner that may violate this Policy, the law, or your personal privacy.</p>
                  </div>
                </li>
              </ol>

              <br />
              <br />

              <p>© 2020 Trusttidy Online Service. All Rights Reserved.</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Terms;
