import React, { useEffect } from "react";
import { HashRouter, Switch, Route } from "react-router-dom";
import Notfound from "./components/404/notfound";
import SiteNav from "./components/navbar/navbar";
import Home from "./components/home/home";
import WhyUs from "./components/whyUs/whyUs";
// import Services from "./components/ourServices/services";
import ServicesCat from "./components/ourServices/service_catalogue";
import Faq from "./components/faq/faq";
import Terms from "./components/terms/terms";
// import Freebies from "./components/freebies/freebies";
import ServiceDetails from "./components/ourServices/service_details";
// import ContactForm from "./components/contact/contact";
import Footer from "./components/footer/footer";

function App() {
  useEffect(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = "https://embed.tawk.to/5f1d4c3a5e51983a11f5d0a5/default";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, []);

  return (
    <HashRouter>
      <>
        <header>
          <SiteNav></SiteNav>
        </header>
        <main>
          <Switch>
            <Route path="/" exact render={() => <Home></Home>} />

            <Route path="/about-us" exact render={() => <WhyUs></WhyUs>} />

            <Route path="/about" exact render={() => <WhyUs></WhyUs>} />

            <Route path="/our-services" exact render={() => <ServicesCat></ServicesCat>} />

            <Route path="/services" exact render={() => <ServicesCat></ServicesCat>} />

            <Route path="/service" exact render={() => <ServicesCat></ServicesCat>} />

            <Route path="/service/:serviceId" component={ServiceDetails} />

            <Route path="/faq" exact render={() => <Faq></Faq>} />

            <Route path="/privacy-policy" exact render={() => <Terms></Terms>} />

            <Route path="/privacy" exact render={() => <Terms></Terms>} />

            <Route path="/policy" exact render={() => <Terms></Terms>} />

            {/* <Route path="/contact-us" exact render={() => <ContactForm></ContactForm>} /> */}

            <Route render={() => <Notfound />} />
          </Switch>

          {/* <Freebies></Freebies> */}
        </main>
        <footer>
          <Footer></Footer>
        </footer>
      </>
    </HashRouter>
  );
}

export default App;
