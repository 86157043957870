import React, { Component } from "react";
// import { HashLink, Link } from "react-router-hash-link";
import { home_cleaning_service_list, corporate_cleaning_service_list, pest_control_service_list, exterior_cleaning_service_list } from "./service_list";
import Service from "./service";

import "./services.scss";
import underlineImg from "../../assets/img/underline.svg";

class ServicesCat extends Component {
  render() {
    return (
      <>
        <section style={{ backgroundColor: "rgb(246, 247, 253)" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="with-underline">Our Range of Services</h2>
                <img src={underlineImg} alt="underline" aria-hidden="true" className="underline" />

                <h4 className="sub-heading">Home Cleaning</h4>
                <p>A range of home cleaning packages to suit your needs. You can order for bathroom, bedroom, kitchen deep cleaning, furniture and furnishing cleaning, floor scrubbing and polishing services as well as an all-inclusive Full Home Deep Cleaning.</p>
                <Service hcs_list={home_cleaning_service_list}></Service>

                <br />
                <br />
                <h4 className="sub-heading">Corporate Cleaning</h4>
                <p>First impression always counts, having an immaculate clean environment will most definitely attract much more clients to the business. A clean workplace is also known to increase staff productivity and decrease absence caused by illness. We use the most up-to-date ergonomically designed, environmentally friendly cleaning equipment and materials. Our cleaning Process enable us to deliver quality cleaning services while helping you to meet your environmental and corporate responsibilities.</p>
                <Service hcs_list={corporate_cleaning_service_list}></Service>

                <br />
                <br />
                <h4 className="sub-heading">Pest Control</h4>
                <p>Choose from a set of pest control services like Cockroach control, Bed Bugs Removal, Rodent Control, Ant Control, Termite Control, General pest control etc.</p>
                <Service hcs_list={pest_control_service_list}></Service>

                <br />
                <br />
                <h4 className="sub-heading">Exterior Cleaning</h4>
                <Service hcs_list={exterior_cleaning_service_list}></Service>

                <br />
                <br />

                <div className="why-us-contact" style={{ maxWidth: "max-content", margin: "auto" }}>
                  <div className="wrapper">
                    <div className="ring">
                      <div className="coccoc-alo-phone coccoc-alo-green coccoc-alo-show">
                        <div className="coccoc-alo-ph-circle"></div>
                        <div className="coccoc-alo-ph-circle-fill"></div>
                        <div className="coccoc-alo-ph-img-circle">
                          <i className="fas fa-phone-alt"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div>
                    For any enquiry or bookings, please contact us on <b>9538 97 98 99</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ServicesCat;
