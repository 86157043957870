const cleaning_acc_data = [
  {
    acc_title: "1. How is TrustTidy’s Deep Cleaning service different from our day-to-day home cleaning?",
    acc_content: "Your day to day cleaning is limited to sweeping and mopping of the floors, where one tends to ignore the nooks, crevices and corners of the house. More often than not your regular cleaning does not take care of deep grime and stains. Our Deep Cleaning service provides thorough cleaning, covering every corner of your house with extensive focus on removal of stains and hard water marks. Our endeavor is to give your home, a spa-like treatment, with the help of a professionally trained crew, equipped with state of the art machinery and eco-friendly chemicals.",
  },
  {
    acc_title: "2. What are the services provided under Deep Cleaning?",
    acc_content: "The list of services included in Deep Cleaning are given below , however it may vary depending on the room in your house that’s being serviced (Living room, Bathroom, Kitchen etc:",
    additional_info: ["Scrubbing of floors using machinery", "Vacuuming the entire house & upholstery (curtains, AC, etc.)", "Dusting & wiping of the furniture - top, front and underneath", "Cleaning of fans, light fixtures, windows, window sills, ledges etc.", "Dusting of walls and removing cobwebs", "Scrubbing the wall tiles & stain removal (Bathroom, Kitchen)", "Descaling water taps – using special cleaning agents (Bathroom, Kitchen)", "Emptying unwanted waste materials & garbage out.", "Finish off by sanitizing and disinfecting the living areas – using eco friendly chemicals"],
  },
  {
    acc_title: "3. What is the duration of a Deep Cleaning service?",
    acc_content: "A typical full home Deep Cleaning service can take anywhere between 6-8 hours depending on the size of the house/ apartment & the amount of dirt",
  },
  {
    acc_title: "4. When is an ideal time to get Deep Cleaning done for my house?",
    acc_content: "We recommend Deep Cleaning in the following cases:",
    additional_info: ["Moving into a new house", "Moving back into a house that has been shut for awhile", "House that has been recently painted", "Your Spring Cleaning time", "Before any auspicious occasion (Housewarming, Diwali, Weddings etc.)"],
  },
  {
    acc_title: "5. How often should I get Deep Cleaning done for my home?",
    acc_content: "To keep your home sanitized & clean, we recommend Deep Cleaning service once every 4 months.",
  },
  {
    acc_title: "6. What is not included in Deep Cleaning of the house?",
    acc_content: "Even though Deep Cleaning is an extensive exercise, there a few things which are not included in the service. Major exclusions are: Sofa shampooing, mattress shampooing, utensil cleaning, washing clothes, cleaning the interiors of the kitchen appliances & personal wardrobes, chandeliers & clearing of construction debri. You can however book some of these services separately - like Sofa Shampooing, & Mattress Shampooing.",
  },
  {
    acc_title: "7. Do you offer warranty on your work?",
    acc_content: "Please inspect the premises post completion of cleaning and if not happy, inform us immediately.",
  },
  {
    acc_title: "8. Do you use acids?",
    acc_content: "We do not use any form of acid for our cleaning services.",
  },
  {
    acc_title: "9. What is Basic Cleaning?",
    acc_content: "Basic Cleaning is our express service wherein our professionally trained crew does the entire cleaning in a matter of 2-3 hours. Basic Cleaning focuses on cleaning the dust, germs, cobwebs & is more of a manual cleaning.",
  },
  {
    acc_title: "10. What is not included in Basic cleaning?",
    acc_content: "Major exclusions are: Mechanized scrubbing of the floors, Sofa shampooing, mattress shampooing, stain removal, utensils cleaning, washing the clothes, cleaning the interiors of the kitchen appliances, kitchen closets & personal wardrobes, chandeliers & clearing of construction debri. You can however book some of these services separately - like Sofa Shampooing, & Mattress Shampooing.",
  },
  {
    acc_title: "11. How often should I get Basic cleaning done for my home?",
    acc_content: "To keep your home clean, we recommend our Basic Cleaning service once every month.",
  },
  {
    acc_title: "12. When is an ideal time to get Deep Cleaning done for my house?",
    acc_content: "We recommend General cleaning in the following cases:",
    additional_info: ["To keep your home sanitized & hygienic", "Before & after hosting a party at your house", "As and when the occasion demands"],
  },
  {
    acc_title: "13. Why is Deep Cleaning expensive - as compared to Basic Cleaning service?",
    acc_content: "Deep Cleaning is slightly more expensive compared to basic cleaning because of the depth and thoroughness of the service. The service is carried out by a professionally trained cleaning crew who use state of the art machinery and eco-friendly chemicals.",
  },
  {
    acc_title: "14. Are there any cancellation charges?",
    acc_content: "If you inform us about the cancellation 12 hours prior to the scheduled slot, there is no cancellation charge. However, if there are any cancellations within the last 12 hours, we charge a cancellation fee up to 50% of the job value.",
  },
  {
    acc_title: "15. What about damages & theft of personal belongings?",
    acc_content: "Trusttidy will not be responsible for any form of theft and damage in your house. Our crew is professionally trained, background verified & thoroughly checked, but you are advised to keep an eye on your belongings and also make sure that any sensitive material is carefully moved under your supervision. Also, when our team is leaving your premises, please make sure that you have thoroughly checked the entire home.",
  },
  {
    acc_title: "16. Are there any charges for inspection?",
    acc_content: "We charge a nominal fee between Rs. 100 to Rs.300 towards inspection charges. Please check the charges in your city while booking a job.",
  },
  {
    acc_title: "17. Are the professionals from TrustTidy or a Third Party?",
    acc_content: "The professionals are contractual partners of Trusttidy and are verified & trained.",
  },
  {
    acc_title: "18. Will you carry all equipment/products required for the service?",
    acc_content: "Yes, we will carry all the products/equipment required for the service. However we would need few things from you to carry out our services smoothly i.e, water, a working plug point & access to a wash basin/handwash.",
  },
  {
    acc_title: "19. Will they clean the external areas of the apartment/villa/house?",
    acc_content: "Balconies/Verandas can be cleaned at an additional charge which will be provided based on inspection.",
  },
  {
    acc_title: "20. Do you carry ladders?",
    acc_content: "We will carry a 5 ft ladder.",
  },
  {
    acc_title: "21. Would the prices change in case the area I specified at the time booking is lesser than the actual area?",
    acc_content: "If the area of the house/apartment/flat is more than what has been quoted/specified, additional charges will apply based on inspection.",
  },
  {
    acc_title: "22. What if I am not happy with the service or the service provider?",
    acc_content: "If you do have any complaints, suggestions or feedback with regard to the service quality, or pricing etc please report it to our  Customer Support Desk at 9620 333435 or mail us at support@trusttidy.com. It is advised to do this within 24hrs of your service.",
  },
];

const pest_control_acc_data = [
  {
    acc_title: "1. How is cockroach control treatment done?",
    acc_content: "In the cockroach control treatment, the technician will apply an odourless spray of eco-friendly chemical along the corners of the walls & place gel in the cupboards. The entire process should take up to 30 minutes depending upon the level of infestation.",
  },
  {
    acc_title: "2. Can I get herbal treatment done?",
    acc_content: "We do not provide herbal treatment since we only use our standard materials.",
  },
  {
    acc_title: "3. Do you provide warranty on cockroach control treatment?",
    acc_content: "We do offer warranty. Please check for warranty details while booking a job. However, we recommend you to go for an AMC (Annual Maintenance Contract) wherein we provide 3 or 4 (as selected) mandatory services over a period of one year to effectively keep the pests away.",
  },
  {
    acc_title: "4. How is Bed Bug treatment done?",
    acc_content: "In the Bedbug treatment, we provide 2 compulsory services within an interval of 15 days. Our technician will do a fumigation process & spray eco-friendly chemical on the infested area/items (eg. beds) in your apartment/house both the times. The entire process will take upto 1 hour.",
  },
  {
    acc_title: "5. What precautions should I take after the Bedbug treatment is done?",
    acc_content: "As a safety precaution, you are requested to vacate the apartment/house for at least 12 hours. Also, we request you to wash all bed linen, curtains & other clothing articles that may have come in contact with the infested area/items.",
  },
  {
    acc_title: "6. Do you provide warranty on Bedbug treatment?",
    acc_content: "We do offer warranty. Please check for warranty details while booking a job.",
  },
  {
    acc_title: "7. How is Anti-Termite treatment done?",
    acc_content: "In the Anti-Termite treatment, our technician will perform a 3 step process. He will drill holes at the junction of the wall & the floor of your house at a spacing of 1 ft each. These holes will be filled with a disinfectant chemical & then the holes are sealed using chalk & white cement. The whole process might take an entire day or more depending on the size of the house & the level of infestation.",
  },
  {
    acc_title: "8. What is the warranty on Anti-Termite treatment?",
    acc_content: "We offer 1 year warranty on the Anti-Termite treatment. It may vary across infestation level - please check before booking the service.",
  },
  {
    acc_title: "9. How is Mosquito control done?",
    acc_content: "In mosquito control, we spray mosquito repellents inside your house/apartment. In extreme cases, we may carry out the fogging process.",
  },
  {
    acc_title: "10. What is the warranty on Mosquito treatment?",
    acc_content: "Their is no warranty on this treatment as the source of problem (eg stagnant water) is usually located outside the your house/apartment.",
  },
  {
    acc_title: "11. How is Rodent Control done?",
    acc_content: "We do rodent control using multiple types of Rodent traps (eg. Sticky glue pads, traps etc.). Also, we help you in identifying the possible location of entry & exit of rodent so that you can get it sealed properly in order to prevent it from happening again.",
  },
  {
    acc_title: "12. What is the warranty on Rodent control?",
    acc_content: "There is no warranty on this treatment.",
  },
  {
    acc_title: "13. What is the warranty on General pest control?",
    acc_content: "We offer a warranty of 30 days for Cockroach & No Warranty for Ant & Flies. However, we recommend you to go for an AMC (Annual Maintenance Contract) wherein we provide 3 mandatory services over a period of one year to effectively keep the pests away.",
  },
  {
    acc_title: "14. What if I am not happy with the service or the service provider?",
    acc_content: "If you do have any complaints, suggestions or feedback with regard to the service quality, or our pricing etc please report it to our Customer Support Desk at 9620 333435 or mail us at support@trusttidy.com It is advised to do this within 24hrs of your service.",
  },
];

export { cleaning_acc_data, pest_control_acc_data };
