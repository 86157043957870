import React, { Component } from "react";
import "./whyUs.scss";
import underlineImg from "../../assets/img/underline.svg";

class WhyUs extends Component {
  render() {
    return (
      <section>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <h2 className="with-underline">About us</h2>
              <img src={underlineImg} alt="underline" aria-hidden="true" className="underline" />
              <p>TrustTidy is your one-stop solution to all kind of commercial & Residential cleaning needs professionally, We simplify your everyday living with a various services, delivered by verified & qualified professionals only.</p>
              <ul className="why-us-list">
                <li>
                  <i className="fas fa-rupee-sign"></i> Reasonable prices
                </li>
                <li>
                  <i className="fas fa-user-check"></i> Verified and Experienced professionals
                </li>
                <li>
                  <i className="fas fa-thumbs-up"></i> Guaranteed satisfaction
                </li>
                <li>
                  <i className="fab fa-pagelines"></i> Eco-friendly chemicals
                </li>
                <li>
                  <i className="fas fa-file-invoice-dollar"></i> Insured Work
                </li>
                <li>
                  <i className="fas fa-sync-alt"></i> Re-work Assurance
                </li>
                <li>
                  <i className="fas fa-headset"></i> Professional Support
                </li>
              </ul>

              <br />

              <div className="why-us-contact">
                <div className="wrapper">
                  <div className="ring">
                    <div className="coccoc-alo-phone coccoc-alo-green coccoc-alo-show">
                      <div className="coccoc-alo-ph-circle"></div>
                      <div className="coccoc-alo-ph-circle-fill"></div>
                      <div className="coccoc-alo-ph-img-circle">
                        <i className="fas fa-phone-alt"></i>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  For any enquiry or bookings, please contact us on <b>9538 97 98 99</b>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="why-us-box">
                <p>
                  <i className="fas fa-user-shield"></i>
                </p>
                <p>
                  100% satisfaction guaranteed
                  <br />
                  or free re-work
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default WhyUs;
