import React, { Component } from "react";
import { home_cleaning_service_list, corporate_cleaning_service_list, pest_control_service_list, exterior_cleaning_service_list } from "./service_list";
import "./services.scss";
import underlineImg from "../../assets/img/underline.svg";

class ServiceDetails extends Component {
  render() {
    const currentServiceID = this.props.match.params.serviceId;
    const serviceList = [...home_cleaning_service_list, ...corporate_cleaning_service_list, ...pest_control_service_list, ...exterior_cleaning_service_list];
    let currentService = null;
    let formattedEle = null;

    for (let i = 0; i < serviceList.length; i++) {
      if (serviceList[i].id === currentServiceID) {
        currentService = { ...serviceList[i] };
      }
    }

    if (currentService !== null) {
      formattedEle = (
        <div className="col-lg-12">
          <h2 className="with-underline">{"Service: " + currentService.title}</h2>
          <img src={underlineImg} alt="underline" aria-hidden="true" className="underline" />

          {currentService.special_note !== null && currentService.special_note !== "" && currentService.special_note !== undefined ? <p>{currentService.special_note}</p> : null}

          {currentService.approx_time !== null && currentService.approx_time !== "" && currentService.approx_time !== undefined ? <p>{currentService.approx_time}</p> : null}

          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <img src={currentService.image} alt={currentService.title + " thumb"} className="img-thumbnail" />
              <br />
              <br />
            </div>
            <div className="col-lg-8 col-md-6 col-sm-12">
              {currentService.desc !== null && currentService.desc !== "" && currentService.desc !== undefined ? <p>{currentService.desc}</p> : null}

              {currentService.additional_info !== null && currentService.additional_info !== "" && currentService.additional_info !== undefined && currentService.additional_info.length !== 0 ? (
                <>
                  {currentService.additional_info.map((currVal) => {
                    return (
                      <>
                        <p style={{ marginBottom: "0px" }}>
                          <strong>{currVal.title}</strong>
                        </p>
                        <ul>
                          {currVal.values.map((currItem) => {
                            return <li>{currItem}</li>;
                          })}
                        </ul>
                      </>
                    );
                  })}
                </>
              ) : null}

              {currentService.inc_excl !== null && currentService.inc_excl !== "" && currentService.inc_excl !== undefined && currentService.inc_excl.length !== 0 ? (
                <>
                  <p style={{ marginBottom: "0px" }}>
                    <strong>Inclusions & Exclusions</strong>
                  </p>
                  <ul>
                    {currentService.inc_excl.map((currVal) => {
                      return <li>{currVal}</li>;
                    })}
                  </ul>
                </>
              ) : null}

              {currentService.procedure !== null && currentService.procedure !== "" && currentService.procedure !== undefined && currentService.procedure.length !== 0 ? (
                <>
                  <p style={{ marginBottom: "0px" }}>
                    <strong>Procedure</strong>
                  </p>
                  <ul>
                    {currentService.procedure.map((currVal) => {
                      return <li>{currVal}</li>;
                    })}
                  </ul>
                </>
              ) : null}

              {currentService.notes !== null && currentService.notes !== "" && currentService.notes !== undefined && currentService.notes.length !== 0 ? (
                <>
                  <p style={{ marginBottom: "0px" }}>
                    <strong>Notes</strong>
                  </p>
                  <ol>
                    {currentService.notes.map((currVal) => {
                      return <li>{currVal}</li>;
                    })}
                  </ol>
                </>
              ) : null}
            </div>
          </div>
        </div>
      );
    } else {
      window.location.href = "#/404";
    }

    return (
      <>
        <section style={{ backgroundColor: "rgb(246, 247, 253)" }}>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">{formattedEle}</div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default ServiceDetails;
