import React, { Component } from "react";
// import { HashLink, Link } from "react-router-hash-link";
import "./services.scss";

class Service extends Component {
  render() {
    let service_boxes = this.props.hcs_list.map((currItem, index) => {
      return (
        <div className="col-lg-3 col-md-6 col-sm-12" key={index}>
          <a className="card h-100" href={"#/service/" + currItem.id} target="_blank" rel="noopener noreferrer">
            <img src={currItem.image} className="card-img-top" alt={currItem.title + " thumb"} />

            <div className="card-body">
              <h5 className="card-title text-center">{currItem.title}</h5>
            </div>
          </a>
        </div>
      );
    });

    return (
      <>
        <div className="row service_thumb_list">{service_boxes}</div>
      </>
    );
  }
}

export default Service;
