import React, { Component } from "react";
import { UncontrolledCarousel } from "reactstrap";
// import WhyUs from "../whyUs/whyUs";
import "./home.scss";
import ServicesCat from "../ourServices/service_catalogue";
import slide0 from "../../assets/img/cleaning.jpg";
import slide1 from "../../assets/img/home1-slide1.jpg";
import slide2 from "../../assets/img/home2-slide1.jpg";
import slide3 from "../../assets/img/blue-covid.jpg";

class Home extends Component {
  items = [
    {
      src: slide0,
      altText: "One-stop solution",
      caption: "We simplify your everyday living with a various services, delivered by verified & qualified professionals.",
      header: "One-stop solution to all kind of cleaning needs",
      key: "1",
    },
    {
      src: slide1,
      altText: "Pest Control Services at Home",
      caption: "Choose from a set of pest control services like Cockroach control, Rat Control, Ant Control, Termite Control, General pest control etc",
      header: "Pest Control Services at Home",
      key: "2",
    },
    {
      src: slide2,
      altText: "Home Cleaning Services",
      caption: "A range of home cleaning packages to suit your needs",
      header: "Home Cleaning Services",
      key: "3",
    },
    {
      src: slide3,
      altText: "Covid 19 prevention",
      caption: "We provide Disinfection service against Corona Virus and overall sanitization services using EPA approved Green disinfectants",
      header: "Covid 19 Sanitization service",
      key: "4",
    },
  ];

  render() {
    return (
      <>
        <UncontrolledCarousel items={this.items} />

        {/* <WhyUs></WhyUs> */}

        <ServicesCat></ServicesCat>
      </>
    );
  }
}

export default Home;
