import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";

import "./navbar.scss";
import siteLogo from "../../assets/img/trusttidy-logo.png";

class SiteNav extends Component {
  state = {
    isOpen: false,
    isCollapseBtnOpen: false,
  };

  handleCollapseBtnToggle = (toggleBtnId, toggleTargetID) => {
    let toggleBtn = document.getElementById(toggleBtnId);
    let toggleTarget = document.getElementById(toggleTargetID);
    // let toggleBtnState = toggleBtn.getAttribute("aria-expanded");

    if (this.state.isCollapseBtnOpen) {
      toggleTarget.style.height = "0px";
      toggleBtn.setAttribute("aria-expanded", "false");
      toggleTarget.classList.remove("show");
      this.setState({
        isOpen: this.state.isOpen,
        isCollapseBtnOpen: false,
      });
    } else {
      console.log(toggleTarget.scrollHeight);
      toggleTarget.style.height = "50px";
      toggleBtn.setAttribute("aria-expanded", "true");
      toggleTarget.classList.add("show");
      this.setState({
        isOpen: this.state.isOpen,
        isCollapseBtnOpen: true,
      });
    }
  };

  handleDropdownToggle = () => {
    if (this.state.isOpen) {
      this.setState({
        isOpen: false,
        isCollapseBtnOpen: this.state.isCollapseBtnOpen,
      });
    } else {
      this.setState({
        isOpen: true,
        isCollapseBtnOpen: this.state.isCollapseBtnOpen,
      });
    }
  };

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link to="/" className="navbar-brand">
            <img src={siteLogo} alt="logo" /> TrustTidy
          </Link>

          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            id="navBarToggleBtn"
            onClick={() => {
              this.handleCollapseBtnToggle("navBarToggleBtn", "navbarSupportedContent");
            }}
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="navbar-collapse" id="navbarSupportedContent" style={{ height: "0px" }}>
            <ul className="navbar-nav ml-auto">
              {/* <li className="nav-item">
                <NavLink exact to="/" className="nav-link" activeClassName="active">
                  Home
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink exact to="/about-us" className="nav-link" activeClassName="active">
                  About us
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink exact to="/our-services" className="nav-link" activeClassName="active">
                  Our Services
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink exact to="/faq" className="nav-link" activeClassName="active">
                  FAQs
                </NavLink>
              </li>

              <li className="nav-item">
                <NavLink exact to="/privacy-policy" className="nav-link" activeClassName="active">
                  Our Privacy & Policy
                </NavLink>
              </li> */}

              <li className="nav-item">
                <button className="nav-link nav-contact-btn">
                  <i className="fas fa-phone-square-alt"></i> 9538 97 98 99
                </button>
                {/* <NavLink exact to="/contact-us" className="nav-link" activeClassName="active">
                  Contact Us
                </NavLink> */}
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

export default SiteNav;
